<template>
  <div class="container-wrap">
    <div class="title">
      <h1>{{ title }}</h1>
    </div>
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    title: String,
  },
};
</script>

<style scoped lang="less">
.title {
  position: relative;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  &::before {
    height: 338px;
    width: 100%;
    left: 0;
    top: -40px;
    position: absolute;
    background: linear-gradient(#0995c1, #8fcb94);
    content: "";
    display: block;
  }
  &::after {
    background-color: #eff3fa;
    position: absolute;
    content: "";
    display: block;
    padding: 15px;
    border-radius: 20px 20px 0 0;
    height: 300px;
    width: 100%;
    left: 0;
    top: 120px;
  }
  h1 {
    color: rgba(255, 255, 255, 100);
    font-size: 17px;
    text-align: center;
    padding: 20px 0;
    position: relative;
  }
}

.container {
  position: relative;
  padding: 0 15px;
  max-width: 1000px;
  margin: auto;
}

.container-wrap {
  min-height: 100vh;
  background-color: #eff3fa;
}
</style>
