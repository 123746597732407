import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Container from "@/components/Container";
import basicContainer from '@/components/basic-container/main';
/**自定义弹窗**/
import customDialog from '@/components/custom-dialog/main';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "@/style/app.less";

//MD5加密
import md5 from 'js-md5';
//sha1加密
import sha1 from 'js-sha1';

Vue.prototype.$md5 = md5;
Vue.prototype.$sha1 = sha1;
Vue.component("Container", Container);
Vue.component("basicContainer", basicContainer);
Vue.component("customDialog", customDialog);
Vue.use(ElementUI);

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
