import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

const Login = () => import("@/views/Login.vue");
const Criterion = () => import("@/views/Criterion.vue");
const Firminfo = () => import("@/views/Firminfo.vue");
const Personal = () => import("@/views/Personal.vue");
const Register = () => import("@/views/Register.vue");
const Retrieve = () => import("@/views/Retrieve.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    meta: {
      isPublic: true,
    },
    component: Login,
  },
  {
    path: "/retrieve",
    meta: {
      isPublic: true,
    },
    component: Retrieve,
  },
  {
    path: "/criterion",
    meta: {
      isPublic: false,
    },
    component: Criterion,
  },
  {
    path: "/firminfo",
    meta: {
      isPublic: false,
    },
    component: Firminfo,
  },
  {
    path: "/personal",
    meta: {
      isPublic: false,
    },
    component: Personal,
  },
  {
    path: "/register",
    meta: {
      isPublic: true,
    },
    component: Register,
  },
  {
    path: '/',
    redirect: '/criterion'
  },
  {
    path: '/404',
    component: () =>
      import( /* webpackChunkName: "page" */ '../components/error-page/404'),
    name: '404',
    meta: {
      isPublic: true,
    }

  },
  {
    path: '/403',
    component: () =>
      import( /* webpackChunkName: "page" */ '../components/error-page/403'),
    name: '403',
    meta: {
      isPublic: true,
    }
  },
  {
    path: '/500',
    component: () =>
      import( /* webpackChunkName: "page" */ '../components/error-page/500'),
    name: '500',
    meta: {
      isPublic: true,
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  store.dispatch("showLoading");
  if (!store.getters.token && !to.meta.isPublic) {
    next({
      path: "/login",
      replace: true,
    });
    return;
  }
  //判断是否为会员
  setTimeout(() => {
    if (store.getters.userInfo.status == 0 && to.path != '/personal') {
      next({
        path: "/personal",
      });
      return;
    }
  },300)

  next();
});

router.afterEach(() => {
  store.dispatch("hideLoading");
});

export default router;
