const state = {
  isLoading: false,
  loadingText: "加载中",
};

const mutations = {
  APP_UPDATE_LOADING(state, { isLoading, loadingText }) {
    state.isLoading = isLoading;
    state.loadingText = loadingText;
  },
};

const getters = {
  isShowLoading: (state) => state.isLoading,
};

const actions = {
  showLoading({ commit }) {
    commit("APP_UPDATE_LOADING", { isLoading: true });
  },
  hideLoading({ commit }) {
    commit("APP_UPDATE_LOADING", { isLoading: false });
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
