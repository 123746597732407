const state = {
  info: JSON.parse(window.localStorage.getItem('UPDATE_USER_INFO') || '{}'),
};

const mutations = {
  UPDATE_USER_INFO(state, info) {
    state.info = info;
  },
};

const actions = {
  updateUserInfo({ commit }, data) {
    window.localStorage.setItem('UPDATE_USER_INFO',JSON.stringify(data))
    commit("UPDATE_USER_INFO", data);
  },
  handleLogOut({ commit }) {
    window.localStorage.clear()
    commit("UPDATE_USER_INFO", {});
  },
};

const getters = {
  userInfo: (state) => state.info,
  token: (state) => {
    return state.info.hasOwnProperty('access_token') ? state.info.access_token : '';
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
