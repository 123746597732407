<template>
  <transition name="slide-fade">
    <div class="dialog-container" v-if="visible">
      <div class="go-back" @click="handleClick" v-if="leftBtn">
        <i class="el-icon-arrow-left"></i> 返回
      </div>
			<div class="content">
				<slot></slot>
			</div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "customDialog",
    props: {
      visible: {
        type: Boolean
      },
			leftBtn: {
        type: Boolean
      },
    },
    data(){
      return{
        scrollHeight: 0
      }
    },
    watch: {
      // 如果 `question` 发生改变，这个函数就会运行
      visible: function () {
        // console.log(`visible value: ${oldQuestion} -> ${newQuestion}`)
        // if(this.visible){
        //   this.scrollHeight = document.getElementById('avue-view').scrollTop;
        //   document.getElementById('avue-view').style.overflow='hidden';
        //   document.getElementById('avue-view').scrollTop = 0;
        // }else{
        //   document.getElementById('avue-view').style.overflow='auto';
        //   document.getElementById('avue-view').scrollTop = this.scrollHeight;
        // }
      }
    },
    methods: {
      handleClick() {
        // this.$emit('close',false);
        this.$emit('update:visible', false);
        // console.log(this.isShowDialog)
        // this.$emit('close');
        // this.isShowDialog = false;
      }
    }
  };
</script>
<style lang="less" scoped>
  .dialog-container{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
		margin: 0;
    width: 100%;
    height: 100%;
    background: #EBF0FA;
    z-index: 999;
    box-sizing: border-box;
		overflow: auto !important;
    overflow-x: hidden !important;
    margin: 0 auto;
    .go-back{
      width: 100%;
      color: #fff;
      display: inline-block;
      background: transparency;
      line-height: 50px;
      padding-left: 10px;
			position: absolute;
			z-index: 9;
    }
			
		.content{
			width: 100%;
			height: 100%;
		}

  }
  .slide-fade-enter-active {
    transition: all 0.5s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.5s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(100%);
    /*opacity: 0;*/
  }
</style>